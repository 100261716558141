import React from "react";

const ReceiveBitcoin = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.3663 20.7362C41.7515 21.5449 39.9289 22 38 22C31.3726 22 26 16.6274 26 10C26 8.0711 26.4551 6.24849 27.2638 4.63371C25.5773 4.21961 23.8143 4 22 4C9.84974 4 0 13.8497 0 26C0 38.1503 9.84974 48 22 48C34.1503 48 44 38.1503 44 26C44 24.1857 43.7804 22.4228 43.3663 20.7362Z"
      fill="#FFCB12"
      fillOpacity="0.1"
    />
    <path
      d="M22.9999 30.172L28.3639 24.808L29.7779 26.222L21.9999 34L14.2219 26.222L15.6359 24.808L20.9999 30.172V18H22.9999V30.172Z"
      fill="#FFCB12"
    />
    <path
      d="M47.7007 12.4191C46.3649 17.7763 40.9384 21.0365 35.58 19.7006C30.2238 18.365 26.9632 12.9388 28.2996 7.58198C29.6347 2.22422 35.0612 -1.03637 40.418 0.299246C45.7761 1.63486 49.0364 7.06168 47.7007 12.4191Z"
      fill="#F7931A"
    />
    <path
      d="M42.1427 8.6935C42.3334 7.29249 41.3627 6.53934 40.0353 6.0369L40.4659 4.13905L39.4146 3.85115L38.9954 5.69899C38.7191 5.62331 38.4352 5.55191 38.1531 5.48117L38.5753 3.62115L37.5246 3.33325L37.0938 5.23044C36.865 5.17319 36.6404 5.11661 36.4225 5.05705L36.4236 5.05113L34.9738 4.65332L34.6942 5.88719C34.6942 5.88719 35.4742 6.08362 35.4577 6.0958C35.8835 6.2126 35.9604 6.52222 35.9476 6.76768L35.4571 8.92974C35.4864 8.93797 35.5245 8.94982 35.5664 8.96824C35.5314 8.9587 35.4939 8.94817 35.4553 8.93797L34.7678 11.9667C34.7157 12.1088 34.5837 12.3221 34.286 12.2411C34.2965 12.2579 33.5219 12.0315 33.5219 12.0315L33 13.3539L34.3681 13.7287C34.6226 13.7987 34.872 13.8721 35.1175 13.9412L34.6825 15.8608L35.7326 16.1487L36.1634 14.2495C36.4503 14.3351 36.7288 14.414 37.0012 14.4884L36.5719 16.3787L37.6232 16.6666L38.0582 14.7506C39.8509 15.1234 41.1989 14.9731 41.7663 13.1914C42.2236 11.7568 41.7436 10.9293 40.8004 10.3897C41.4873 10.2156 42.0047 9.71909 42.1427 8.6935ZM39.7407 12.3948C39.4158 13.8293 37.2177 13.0538 36.5051 12.8594L37.0824 10.3163C37.795 10.5117 40.0803 10.8987 39.7407 12.3948ZM40.0659 8.67277C39.7694 9.97771 37.9399 9.31471 37.3465 9.15217L37.8699 6.84567C38.4634 7.00821 40.3746 7.31157 40.0659 8.67277Z"
      fill="white"
    />
  </svg>
);

export default ReceiveBitcoin;
