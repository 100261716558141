/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import { Location } from "src/types";
import { updatingAssets } from "src/aux/updateAssets";
import { AssetView } from "src/types/views";
import RgbAsset from "src/Components/Icons/RgbAsset";

const ImportDescription = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    lnCredentials,
    asset: assetData,
    source,
    hash,
  } = location.state;

  const [loading, setLoading] = useState(false);
  const [assetView, _] = useState(new AssetView(assetData));

  const asset = {
    ticker: assetView.ticker(),
    name: assetView.name(),
    description: assetView.description(),
    amount: assetView.balance(),
    id: assetView.id(),
  };

  const handleSubmit = async () => {
    setLoading(true);
    const futureAssets = await updatingAssets(walletData, assetData);
    console.debug(`futureAssets: ${futureAssets}`);
    navigate("/wallet", {
      state: { wallet: walletData.name, vault, hash, lnCredentials },
    });
  };

  return (
    <PageWrapper
      title="Import Confirmation"
      handlePageBack={() =>
        navigate("/importassets", {
          state: { walletData, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="w-full p-6 pt-0 m-auto mt-6 text-center border-1 dark:border-darkmode-700 rounded-xl">
        <RgbAsset className="w-20 h-20 m-auto" />
        <p className="my-3 text-2xl font-semibold text-black dark:text-gray-300">
          {`${Number(
            assetView.supply()
          ).toLocaleString()} ${assetView.ticker()}`}
        </p>
        <p className="text-base font-thin text-gray-800 dark:text-gray-500">
          {assetView.name()}
        </p>
      </div>
      <div className="w-full pb-3 m-auto my-3 text-black sm:my-0 dark:text-gray-400 sm:pb-6">
        <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Total supply
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {assetView.supply()}
          </p>
        </div>
        <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            {assetView.id() ? "Asset ID" : "Genesis ID"}
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {assetView.id()}
          </p>
        </div>
        {assetView.description() && (
          <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
            <p className="my-4 text-lg font-semibold text-left flex-grow-default">
              Description
            </p>
            <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
              {assetView.description()}
            </p>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ImportDescription;
