import React from "react";

const CoinCircle = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 44 44"
  >
    <g clipPath="url(#clip0_90_6335)">
      <circle cx="22" cy="22" r="22" fill="#FFCB12" fillOpacity="0.1" />
      <path
        d="M22 14C28.075 14 33 16.686 33 20V24C33 27.314 28.075 30 22 30C16.033 30 11.176 27.409 11.005 24.177L11 24V20C11 16.686 15.925 14 22 14ZM22 26C18.28 26 14.99 24.993 13 23.45V24C13 25.882 16.883 28 22 28C27.01 28 30.838 25.97 30.995 24.118L31 24L31.001 23.45C29.011 24.992 25.721 26 22 26ZM22 16C16.883 16 13 18.118 13 20C13 21.882 16.883 24 22 24C27.117 24 31 21.882 31 20C31 18.118 27.117 16 22 16Z"
        fill="#FFCB12"
      />
    </g>
    <defs>
      <clipPath id="clip0_90_6335">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CoinCircle;
