import React from "react";

const SendLightning = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.3663 20.7362C41.7515 21.5449 39.9289 22 38 22C31.3726 22 26 16.6274 26 10C26 8.0711 26.4551 6.24849 27.2638 4.63371C25.5773 4.21961 23.8143 4 22 4C9.84974 4 0 13.8497 0 26C0 38.1503 9.84974 48 22 48C34.1503 48 44 38.1503 44 26C44 24.1857 43.7804 22.4228 43.3663 20.7362Z"
      fill="#FFCB12"
      fillOpacity="0.1"
    />
    <path
      d="M20.9998 21.828L15.6358 27.192L14.2218 25.778L21.9998 18L29.7778 25.778L28.3638 27.192L22.9998 21.828L22.9998 34L20.9998 34L20.9998 21.828Z"
      fill="#FFCB12"
    />
    <path
      d="M47.7007 12.4191C46.3649 17.7763 40.9384 21.0365 35.58 19.7006C30.2238 18.365 26.9632 12.9388 28.2996 7.58198C29.6347 2.22422 35.0612 -1.03637 40.418 0.299246C45.7761 1.63486 49.0364 7.06168 47.7007 12.4191Z"
      fill="#6A00F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7175 4.5758C40.823 4.08346 40.2402 3.74085 39.8613 4.07241L33.1797 9.9188C32.8161 10.2369 33.0411 10.8358 33.5243 10.8358H35.6743L34.6911 15.424C34.5856 15.9163 35.1683 16.259 35.5472 15.9274L42.2288 10.081C42.5924 9.76287 42.3674 9.16403 41.8843 9.16403H39.7343L40.7175 4.5758Z"
      fill="white"
    />
  </svg>
);

export default SendLightning;
