import React from "react";
import { motion } from "framer-motion";
import Loader from "src/Components/Loaders/Loader";
import { classNames } from "src/Hooks/util";

const IconAndLabelButton = ({ className = "", ...props }) => (
  <div
    className={classNames(
      props.disabled ? "opacity-50 cursor-not-allowed" : ""
    )}
  >
    <motion.button
      whileHover={{ scale: props.disabled ? 1 : 1.02 }}
      whileTap={{ scale: props.disabled ? 1 : 0.98 }}
      type="button"
      className={`${className} rounded-xl hover:shadow-md shadow-sm flex justify-center font-medium px-6 py-3 m-auto text-center flex-nowrap disabled:opacity-50 disabled:cursor-not-allowed`}
      onClick={() => (props.disabled ? null : props.onClick())}
      data-test-id={props["data-test-id"]}
      disabled={props.disabled}
    >
      {props.loading ? (
        <Loader className="w-6 h-6 my-auto mr-3" />
      ) : (
        props.children
      )}
    </motion.button>
    <p className="m-auto mt-2 text-base font-thin text-center text-yellow-500 xs:text-lg">
      {props.label}
    </p>
  </div>
);

export default IconAndLabelButton;
