import React from "react";

const SwapCircle = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 44 44"
  >
    <g clipPath="url(#clip0_62_2129)">
      <circle cx="22" cy="22" r="22" fill="#FFCB12" fillOpacity="0.1" />
      <path
        d="M21.95 25.9999L17 30.95L12.05 25.9999L13.464 24.586L16.001 27.122L16 13.95L18 13.95L18 27.122L20.536 24.586L21.95 25.9999ZM31.95 17.9L30.536 19.314L28 16.778L28 29.95L26 29.95L26 16.778L23.464 19.314L22.05 17.9L27 12.95L31.95 17.9Z"
        fill="#FFCB12"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_2129">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SwapCircle;
