import React, { useState } from "react";
import { classNames } from "src/Hooks/util";
import SearchIcon from "../Icons/Search";

const Search = ({ className, ...props }) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <div className={classNames(className)}>
      <div className="flex w-full flex-grow-default rounded-xl shadow-sm">
        <div className="relative flex items-stretch flex-grow-default focus-within:z-10">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <SearchIcon
              className="w-5 h-5 text-gray-900 dark:text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="string"
            name="string"
            id="string"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearch(inputValue);
              }
            }}
            className="block w-full dark:text-white rounded-none dark:autofill:bg-newdarkmode-600 placeholder:font-light rounded-l-xl border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset bg-gray-200 dark:bg-newdarkmode-600 ring-gray-400 dark:ring-darkmode-600 placeholder:text-gray-600 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-gray-500 dark:focus:ring-darkmode-400 sm:text-sm sm:leading-6"
            placeholder="Search by ticker"
          />
        </div>
        <button
          type="button"
          onClick={() => props.setSearch(inputValue)}
          className="relative -ml-px inline-flex items-center text-black dark:text-white bg-gray-200 dark:bg-newdarkmode-700 gap-x-1.5 rounded-r-xl px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-400 dark:ring-darkmode-600 placeholder:text-gray-600 dark:placeholder:gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 dark:focus:ring-darkmode-400 hover:bg-gray-50"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Search;
