/* eslint-disable arrow-body-style */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const FullScreenModal = ({ ...props }) => {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={props.open}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center h-auto text-center lg:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative z-40 inline-block w-full h-screen overflow-hidden text-left text-black align-middle bg-white shadow-xl xs:px-3 sm:px-6 md:px-12 xs:mx-auto dark:bg-darkmode-900 dark:text-gray-600 transform transition-all">
              <div className="h-auto m-auto text-center">
                <div className="h-auto m-auto">{props.children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FullScreenModal;
