/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "src/Hooks/util";

const AssetFooter = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <footer className="flex flex-col w-full h-14">
      <div
        className={classNames(
          !props.tokensWalletFunded || props.loadingVault
            ? "flex justify-center"
            : "grid grid-cols-12 gap-6",
          "h-auto m-auto py-2 text-center w-full px-6 lg:px-12 flex justify-center"
        )}
        title={
          props.fundingPending ? "asset vault funding transaction pending" : ""
        }
      >
        {props.loadingVault ? (
          <div className="w-11/12">
            <Skeleton
              baseColor="#1C1C1C"
              highlightColor="#343434"
              className="w-full h-full m-auto"
            />
          </div>
        ) : (
          <>
            {!props.fundingPending ? (
              <>
                {props.view === 1 ? (
                  <>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black dark:bg-darkmode-700 dark:text-white w-full col-span-6 disabled:opacity-25"
                      )}
                      onClick={() => {
                        navigate("/importassets", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            hash: props.hash,
                            lnCredentials: props.lnCredentials,
                          },
                        });
                      }}
                      disabled={props.fundingPending}
                    >
                      <div className="flex flex-row m-auto">
                        <div className="m-auto text-sm lg:text-base">
                          Import Assets
                        </div>
                      </div>
                    </RoundedButton>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black dark:bg-darkmode-700 dark:text-white w-full col-span-6 disabled:opacity-25"
                      )}
                      onClick={() => {
                        navigate("/createasset", {
                          state: {
                            walletData: props.walletData,
                            assetUtxos: props.assetUtxos,
                            vault: props.vault,
                            hash: props.hash,
                            lnCredentials: props.lnCredentials,
                          },
                        });
                      }}
                      disabled={props.fundingPending}
                    >
                      <div className="flex flex-row m-auto">
                        <div className="m-auto text-sm lg:text-base">
                          Create Asset
                        </div>
                      </div>
                    </RoundedButton>
                  </>
                ) : (
                  <>
                    <RoundedButton
                      className="w-full m-auto text-sm text-black sm:px-2 col-span-6 dark:text-white dark:bg-darkmode-700 disabled:opacity-25"
                      onClick={() => {
                        navigate("/accepttransaction", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            hash: props.hash,
                            assetUtxos: props.assetUtxos,
                            lnCredentials: props.lnCredentials,
                            asset: null,
                            type: "UDA",
                            locationHistory: [window.location.hash],
                          },
                        });
                      }}
                      disabled={props.fundingPending}
                    >
                      <div className="flex flex-row m-auto">
                        <div className="m-auto text-sm lg:text-base">
                          Accept UDA
                        </div>
                      </div>
                    </RoundedButton>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black dark:bg-darkmode-700 w-full col-span-6 dark:text-white disabled:opacity-25"
                      )}
                      onClick={() => {
                        // We will need to determine how to decern which asset type we are dealing with
                        // and pass it to receive. Set as Asset for now.
                        navigate("/receive", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            assetUtxos: props.assetUtxos,
                            type: "UDA",
                            precision: 0,
                            locationHistory: [window.location.hash],
                            lnCredentials: props.lnCredentials,
                            tokensWalletFunded: props.tokensWalletFunded,
                            tokensWalletAddress: props.tokensWalletAddress,
                            udasWalletAddress: props.udasWalletAddress,
                            hash: props.hash,
                          },
                        });
                      }}
                      disabled={props.fundingPending}
                    >
                      <div className="flex flex-row m-auto">
                        <div className="m-auto text-sm lg:text-base">
                          Generate Invoice
                        </div>
                      </div>
                    </RoundedButton>
                  </>
                )}
              </>
            ) : (
              <RoundedButton
                className={classNames(
                  "w-11/12 sm:px-2 m-auto text-sm text-black dark:bg-darkmode-700 dark:text-white col-span-12"
                )}
                onClick={() => {
                  props.setOpenFundAssetsModal(true);
                }}
              >
                Fund RGB Vault{" "}
              </RoundedButton>
            )}
          </>
        )}
      </div>
    </footer>
  );
};

export default AssetFooter;
